import Axios from "axios";
import { useState } from "react";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";

const Button = (props) => {
  const [btn, setBtn] = useState(props.status);
  let statusFetch = props.name === "สรุปยอด" ? "status" : "status_f";
  function handleClick(id) {
    const data = btn === "on" ? "off" : "on";
    Axios.get(`${process.env.REACT_APP_API_URL}/order/${statusFetch}/${id}`, { params: { status: data } }).then((response) => {
      // Notify.success("เปลี่ยนสถานะสำเร็จ");
    });
    setBtn(data);
  }

  let toggleClassCheck = btn === "on" ? "bg-green-500" : "bg-red-500";
  let icon = btn === "on" ? <AiOutlineCheck /> : <AiOutlineClose />;
  return (
    <>
      <span
        className={
          "p-1 px-2 rounded-lg ml-1 shadow-lg flex items-center hover:shadow-sm transition cursor-pointer text-slate-200 hover:scale-105 " +
          toggleClassCheck
        }
        onClick={event => handleClick(props.id)}
      >
        <span className="pr-1">{props.name}</span> {icon}
      </span>
    </>
  );
};

export default Button;
