import { useEffect, useRef, useState } from "react";
import io from "socket.io-client";

const TiktokChat = () => {
  const [isRoomConnected, setIsRoomConnected] = useState(false);
  const [data, setData] = useState([]);
  const [name, setName] = useState("wiset.style");
  const [viewer, setViewer] = useState(0);

  const bottomRef = useRef(null);
  const socket = io.connect(`${process.env.REACT_APP_API_TIKTOK_URL}`);
  useEffect(() => {
    // if (isRoomConnected) {
    const eventListener = (msg) => {
      setData((data) => [
        ...data,
        {
          id: msg.uniqueId,
          name: msg.nickname,
          message: msg.comment,
          image: msg.profilePictureUrl,
        },
      ]);
    };
    socket.on("chat", eventListener);

    socket.on("roomUser", (msg) => {
      setViewer(msg.viewerCount);
    });
    // return () => {
    //   socket.off("chat");
    // };
    // }
  }, [data, socket]);
  const scrollToMyRef = () => {
    const scroll =
      bottomRef.current.scrollHeight - bottomRef.current.clientHeight;
    bottomRef.current.scrollTo(0, scroll);
  };
  const joinRoom = () => {
    setIsRoomConnected(true);
    socket.emit("setUniqueId", name);
  };
  useEffect(() => {
    scrollToMyRef();
  }, [data]);
  return (
    <>
      <h1 className="text-slate-200 text-center mt-5 text-2xl">Chat Live !</h1>
      <div className="mt-5">
        <div className="grid grid-cols-2 gap-2">
          <div className="mt-2">
            <input
              type="text"
              className="mt-1 w-full rounded-xl border-gray-300 shadow-sm py-2 pl-3"
              placeholder=""
              value={name}
              onChange={(event) => {
                setName(event.target.value);
              }}
            />
          </div>
          <div className="mt-2">
            <button
              type="button"
              className="w-full bg-body text-slate-200 rounded-xl border-2 border-sky-400 py-2 hover:scale-105 transition items-center"
              onClick={joinRoom}
            >
              Connect{" "}
              {isRoomConnected ? (
                <span className="h-3 w-3 bg-green-500 inline-block rounded-full"></span>
              ) : (
                <span className="h-3 w-3 bg-red-500 inline-block rounded-full"></span>
              )}
            </button>
          </div>
        </div>
      </div>
      <div className="h-screen">
        <div className="mt-5 p-2 bg-secondary rounded-lg text-slate-200 text-right border-sky-500 border-2 border-b-0 rounded-bl-none rounded-br-none">
          ยอดวิว {viewer}
        </div>
        <div
          className="bg-slate-100 h-3/5 w-full rounded-lg overflow-auto rounded-tl-none rounded-tr-none"
          ref={bottomRef}
        >
          {data.map((val, key) => {
            return (
              <div
                className="grid grid-cols-7 gap-2 p-3 content-around border-b-sky-500 border-b-2"
                key={key}
              >
                <div className="flex col-span-3 items-center">
                  <img className="rounded-full h-10" src={val.image} />{" "}
                  <span className="ml-3">{val.id}</span>
                </div>
                <div className="col-span-2 items-center">{val.name}</div>
                <div className="col-span-2 items-center">{val.message}</div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default TiktokChat;
