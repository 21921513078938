
const Report = (data) => {
    const count = data.data.reduce((previousValue, currentValue) => {
        let i = previousValue+=1;
        return i;
    },0);
    const countsum = data.data.reduce((previousValue, currentValue) => {
        return previousValue + currentValue.totalsum;
    },0);
    const countbuy = data.data.reduce((previousValue, currentValue) => {
        return previousValue + currentValue.count;
    },0);
    const countprofit = data.data.reduce((previousValue, currentValue) => {
        return previousValue + currentValue.profit;
    },0);
    const countreal = data.data.reduce((previousValue, currentValue) => {
        let i = (currentValue.status_f === 'on' ) ? previousValue+=1 : previousValue;
        return i;
    },0);
    
    return (
        <>
            <div className="grid grid-cols-3 mt-5">
                <div className="span-col-1 text-xl text-slate-200">
                    <div className="m-3 p-3 border-sky-500 border-2 bg-secondary h-15 text-center rounded-xl">
                        สั่งซื้อ<br />
                        {count} คน
                    </div>
                </div>
                <div className="span-col-1 text-xl text-slate-200">
                    <div className="m-3 p-3 border-sky-500 border-2 bg-secondary h-15 text-center rounded-xl">
                        โอนแล้ว<br />
                        {countreal} คน
                    </div>
                </div>
                <div className="span-col-1 text-xl text-slate-200">
                    <div className="m-3 p-3 border-sky-500 border-2 bg-secondary h-15 text-center rounded-xl">
                        ยอดโอน<br />
                        {countsum.toLocaleString()}
                    </div>
                </div>
                <div className="span-col-1 text-xl text-slate-200">
                    <div className="m-3 p-3 border-sky-500 border-2 bg-secondary h-15 text-center rounded-xl">
                        สั่งซื้อ<br />
                        {countbuy} ชิ้น
                    </div>
                </div>
                {/* <div className="span-col-1 text-xl text-slate-200">
                    <div className="m-3 p-3 border-sky-500 border-2 bg-secondary h-15 text-center rounded-xl">
                        กำไร<br />
                        {countprofit.toLocaleString()} บาท
                    </div>
                </div> */}
            </div>
        </>
    );
};

export default Report;
