import { useEffect, useState } from "react";
import Axios from "axios";
import Tag from "./tag.js";
import { useNavigate } from "react-router-dom";
import Serialize from "./serialize.js"
const Create = () => {
  const [product, setProduct] = useState("");
  const [id, setId] = useState("");
  const [nameid, setnameId] = useState("");
  const [name, setName] = useState("");
  const [tiktok, setTiktok] = useState("");
  const [tiktoklink, setTiktoklink] = useState("");
  const [line, setLine] = useState("");
  const [search, setSearch] = useState([]);
  const [date, setDate] = useState([]);
  const navigate = useNavigate();

  const addOrder = () => {
    const params = {
      id: id,
      name: name,
      tiktok: tiktok,
      tiktok_link: tiktoklink,
      line: line,
      product: Serialize(product),
      date: date.date,
      time: date.time,
    };

    if (id) {
      Axios.post(`${process.env.REACT_APP_API_URL}/createexist`, params).then(
        () => { 
navigate("/");
        }
      );
    } else {
      Axios.post(`${process.env.REACT_APP_API_URL}/create`, params).then(
        () => {
navigate("/");
         }
      );
    }
  };

  const getSearch = async (prev) => {
    let searchdata = await Axios.get(`${process.env.REACT_APP_API_URL}/search?name=${prev}`);
    let data = searchdata.data.map(({ id, name, line, tiktok,tiktok_link }) => ({
      value: id,
      name: name,
      line: line,
      tiktok: tiktok,
      tiktok_link: tiktok_link,
    }));
    setSearch(data);
    if(!prev){
    setSearch([]);
    }
  };

  const getDate = async () => {
    let data = await Axios.get(`${process.env.REACT_APP_API_URL}/date`);
    setDate(data.data[0]);
  };

  function handleChange(event) {
    setProduct(event);
  }
  function handleId(event,name) {
    setId(event);
    setnameId(name);
    setSearch([]);
  }
  function clear() {
    setId("");
    setnameId("");
  }

  useEffect(() => {
    getDate();
  }, []);

  return (
    <>
      <h1 className="text-slate-200 text-center mt-5 text-2xl">
        สร้างคำสั่งซื้อ
      </h1>
      <form action="">
        <div className="bg-slate-100 text-slate-800 p-4 my-3 rounded-lg mx-3 relative mb-4 pb-8">
          <div className="mt-2">
            <label htmlFor="id">ค้นหาชื่อ</label>
            <input type="text" className="mt-1 w-full rounded-xl border-gray-300 shadow-sm py-2 pl-3" onChange={(e) => getSearch(e.target.value)}
            />
            {search.length > 0 ? (
            <div className="bg-body text-slate-100 rounded-lg p-2 m-2 absolute overflow-scroll max-h-96">
              {search.map((data, keys) => (
                <div
                  className="p-1 mt-2 self-center text-sm cursor-pointer hover:bg-slate-600 rounded-lg transition"
                  key={keys}
                  onClick={(e) => handleId(data.value,`${data.name} ${data.line} ${data.tiktok}`)}
                >
                  {data.value} {data.name} {data.line} {data.tiktok}
                </div>
              ))}
            </div>
            ) : ""
            }
          </div>
          {nameid ? (
            <div className="mt-6 p-2 bg-body text-slate-200 rounded-lg flex justify-between">
              <span>
              คุณได้เลือก {nameid} 

              </span>
              <span className="px-2 pb-1 bg-red-500 rounded-full cursor-pointer" onClick={clear}>
                x
              </span>
              </div>
          ) : ""
          }
          <div className="mt-6">
            <label htmlFor="name">ชื่อเล่น</label>
            <input
              type="text"
              className="mt-1 w-full rounded-xl border-gray-300 shadow-sm py-2 pl-3"
              placeholder=""
              onChange={(event) => {
                setName(event.target.value);
              }}
            />
          </div>
          <div className="mt-6">
            <label htmlFor="tiktok">Tiktok</label>
            <input
              type="text"
              className="mt-1 w-full rounded-xl border-gray-300 shadow-sm py-2 pl-3"
              placeholder=""
              onChange={(event) => {
                setTiktok(event.target.value);
              }}
            />
          </div>
          <div className="mt-6">
            <label htmlFor="tiktok_link">TikTok ลิงก์</label>
            <input
              type="text"
              className="mt-1 w-full rounded-xl border-gray-300 shadow-sm py-2 pl-3"
              placeholder=""
              onChange={(event) => {
                setTiktoklink(event.target.value);
              }}
            />
          </div>
          <div className="mt-6">
            <label htmlFor="line">ไลน์</label>
            <input
              type="text"
              className="mt-1 w-full rounded-xl border-gray-300 shadow-sm py-2 pl-3"
              placeholder=""
              onChange={(event) => {
                setLine(event.target.value);
              }}
            />
          </div>
        </div>
        <div className="bg-slate-100 text-slate-800 p-4 my-3 rounded-lg mx-3 relative mb-3 pb-3">
          <div className="mt-2">
            <label>สินค้า</label>
            <Tag handleChange={handleChange} />
            {/* <input
              type="text"
              className="mt-1 w-full rounded-xl border-gray-300 shadow-sm py-2 pl-3"
              placeholder=""
              onChange={(event) => {
                setProduct(event.target.value);
              }}
            /> */}
          </div>
        </div>
        <div className="m-2">
          <button
            type="button"
            className="w-full bg-body text-slate-200 rounded-xl border-2 border-sky-400 py-3 hover:scale-105 transition"
            onClick={addOrder}
          >
            เพิ่มข้อมูล
          </button>
        </div>
      </form>
    </>
  );
};

export default Create;
