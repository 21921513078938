import Home from "./home";
import Create from "./create";
import Footer from "./footer";
import Edit from "./edit";
import Settings from "./settings";
import TiktokChat from "./tiktokchat";
import { Route, Routes } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <div className="grid grid-cols-1 lg:grid-cols-2">
        <div className="mb-28 mx-5 hidden lg:block">
          <TiktokChat/>
        </div>
        <div className="mb-28 max-w-lg mx-auto">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/create" element={<Create />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/edit/:ids/:post" element={<Edit />} />
          </Routes>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
