import { BiHomeAlt, BiPlus } from "react-icons/bi";
import { TbSettings } from "react-icons/tb";
import { Link } from "react-router-dom";
const Footer = (buttonText) => {
  return (
    <>
      <div className="fixed bottom-0 left-0 right-0 z-[100]">
        <div className="w-full bg-secondary">
          <div className="grid grid-cols-5 gap-4 h-16">
            <div className="col-span-2 self-center justify-self-center text-2xl text-slate-200">
              <Link to="/">
                <BiHomeAlt />
              </Link>
            </div>
            <div className="col-span-1 self-center justify-self-center text-2xl text-slate-200">
              <Link to="/create">
                <div className="bg-body p-4 rounded-xl -translate-y-6 border-2 border-sky-400">
                  <BiPlus />
                </div>
              </Link>
            </div>
            <div className="col-span-2 self-center justify-self-center text-2xl text-slate-200">
              <Link to="/settings">
                <div className="col-span-2 self-center justify-self-center text-2xl text-slate-200">
                  <TbSettings />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* <Route path="/">
        <Home />
      </Route> */}
      {/* <Route path="/create"></Route> */}
      {/* <Route path="/profile"></Route> */}
    </>
  );
};

export default Footer;
