import { useEffect, useState } from "react";
import Axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Notify } from "notiflix/build/notiflix-notify-aio";
import { Confirm } from 'notiflix/build/notiflix-confirm-aio';

const Edit = () => {
  const { ids, post } = useParams()
  const [name, setName] = useState("");
  const [tiktok, setTiktok] = useState("");
  const [tiktoklink, setTiktoklink] = useState("");
  const [line, setLine] = useState("");
  const navigate = useNavigate();

  const addOrder = () => {
    const params = {
      name: name,
      tiktok: tiktok,
      tiktok_link: tiktoklink,
      line: line,
    };

      Axios.put(`${process.env.REACT_APP_API_URL}/edit/${ids}`, params).then(
        () => {
    Notify.success("แก้ไขสำเร็จ");
        }
      );
    
  };

  const Delete = () => {
    Confirm.show(
      'ลบข้อมูลลูกค้าและคำสั่งซื้อ',
      'คุณต้องการลบใช่ไหม',
      'ตกลง',
      'ยกเลิก',
      () => {
        Axios.delete(`${process.env.REACT_APP_API_URL}/edit/${ids}`).then(
          () => {
            navigate("/");
          }
        );
      },
      );
      
    }

  const DeletePost = () => {
    Confirm.show(
      'ลบข้อมูลคำสั่งซื้อ',
      'คุณต้องการลบใช่ไหม',
      'ตกลง',
      'ยกเลิก',
      () => {
        Axios.delete(`${process.env.REACT_APP_API_URL}/order/${post}`).then(
          () => {
            navigate("/");
          }
        );
      },
      );
      
    }

  const getSearch = async () => {
    let searchdata = await Axios.get(`${process.env.REACT_APP_API_URL}/edit/${ids}`);
    let data = searchdata.data;
    if(data[0]){
      setName(data[0].name);
      setTiktok(data[0].tiktok);
      setTiktoklink(data[0].tiktok_link);
      setLine(data[0].line);
    }
  };

  useEffect(() => {
    getSearch();
  }, []);

  return (
    <>
      <h1 className="text-slate-200 text-center mt-5 text-2xl">
        แก้ไขรายชื่อคุณ {name}
      </h1>
      <form action="">
        <div className="bg-slate-100 text-slate-800 p-4 my-3 rounded-lg mx-3 relative mb-4 pb-8">
          <div className="mt-2 text-right">
          <span className="p-2 bg-red-500 text-white rounded-lg cursor-pointer" onClick={Delete}>ลบข้อมูลลูกค้า</span>
          <span className="p-2 bg-red-500 text-white rounded-lg ml-2 cursor-pointer" onClick={DeletePost}>ลบข้อมูลคำสั่งซื้อ</span>
          </div>
          <div className="mt-2">
            <label htmlFor="name">ชื่อเล่น</label>
            <input
              type="text"
              className="mt-1 w-full rounded-xl border-gray-300 shadow-sm py-2 pl-3"
              placeholder=""
              value={name}
              onChange={(event) => {
                setName(event.target.value);
              }}
            />
          </div>
          <div className="mt-6">
            <label htmlFor="tiktok">Tiktok</label>
            <input
              type="text"
              className="mt-1 w-full rounded-xl border-gray-300 shadow-sm py-2 pl-3"
              placeholder=""
              value={tiktok}
              onChange={(event) => {
                setTiktok(event.target.value);
              }}
            />
          </div>
          <div className="mt-6">
            <label htmlFor="tiktok_link">TikTok ลิงก์</label>
            <input
              type="text"
              className="mt-1 w-full rounded-xl border-gray-300 shadow-sm py-2 pl-3"
              placeholder=""
              value={tiktoklink}
              onChange={(event) => {
                setTiktoklink(event.target.value);
              }}
            />
          </div>
          <div className="mt-6">
            <label htmlFor="line">ไลน์</label>
            <input
              type="text"
              className="mt-1 w-full rounded-xl border-gray-300 shadow-sm py-2 pl-3"
              placeholder=""
              value={line}
              onChange={(event) => {
                setLine(event.target.value);
              }}
            />
          </div>
        </div>
        <div className="m-2">
          <button
            type="button"
            className="w-full bg-body text-slate-200 rounded-xl border-2 border-sky-400 py-3 hover:scale-105 transition"
            onClick={addOrder}
          >
            แก้ไขข้อมูล
          </button>
        </div>
      </form>
    </>
  );
};

export default Edit;
