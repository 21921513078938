import { useEffect, useState } from "react";
import Axios from "axios";
import Datepick from "./date.js";
import Timepick from "./time.js";
import { Notify } from "notiflix/build/notiflix-notify-aio";

const Settings = () => {
  const [ship, setShip] = useState(0);
  const [detail, setDetail] = useState("");

  const getSearch = async () => {
    let searchdata = await Axios.get(`${process.env.REACT_APP_API_URL}/date`);
    let data = searchdata.data;
    if (data[0]) {
      setShip(data[0].dcost);
      setDetail(data[0].detail);
    }
  };

  useEffect(() => {
    getSearch();
  }, []);

  const addOrder = () => {
    const params = {
      dcost: ship,
      detail: detail,
    };

    Axios.put(`${process.env.REACT_APP_API_URL}/additional`, params).then(
      () => {
        Notify.success("แก้ไขสำเร็จ");
      }
    );

  };
  return (
    <>
      <h1 className="text-slate-200 text-center mt-5 text-2xl">
        รายการตั้งค่า
      </h1>
      <div className="grid grid-cols-4">
        <div className="col-span-3 md:col-span-2">
          <Datepick />
        </div>
        <div className="col-span-1 md:col-span-2">
          <Timepick />
        </div>
      </div>
      <div className="bg-slate-100 text-slate-800 p-4 my-3 rounded-lg mx-3 relative mb-4 pb-8">
        <div className="col-span-4">
          <label htmlFor="ship">ค่าจัดส่ง</label>
          <input
            type="number"
            className="mt-1 w-full rounded-xl border-gray-300 shadow-sm py-2 pl-3"
            placeholder=""
            value={ship}
            onChange={(event) => {
              setShip(event.target.value);
            }}
          />
        </div>
        <div className="col-span-4 mt-6">
          <label htmlFor="detail">ข้อความเพิ่มเติม ส่วน Copy</label>
          <textarea
            className="mt-1 w-full rounded-xl border-gray-300 shadow-sm py-2 pl-3"
            rows="6"
            value={detail}
            onChange={(event) => {
              setDetail(event.target.value);
            }}
          />
        </div>
        <div className="col-span-4 mt-6">
          <button
            type="button"
            className="w-full bg-body text-slate-200 rounded-xl border-2 border-sky-400 py-3 hover:scale-105 transition"
            onClick={addOrder}
          >
            แก้ไขข้อมูล
          </button>
        </div>
      </div>

    </>
  );
};

export default Settings;
