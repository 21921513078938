import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Axios from "axios";
import moment from 'moment';

const Timepick = () => {
  const [startDate, setStartDate] = useState(null);

  const getDate = async () => {
    let data = await Axios.get(`${process.env.REACT_APP_API_URL}/date`);
    var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
    if (data.data[0].time) {
      var time = data.data[0].time + ":00";
      var date = new Date(data.data[0].date.replace(pattern, '$3-$2-$1') + 'T' + time);
      setStartDate(date);
    } else {
      setStartDate(null);
    }
    
  }
  const Update = (date) => {
    setStartDate(date);
    const params = {
      time: moment(date).format('HH:mm'),
    };
    Axios.put(`${process.env.REACT_APP_API_URL}/time`, params).then(
      () => {
        // window.location.reload();
      }
    );

  };

  const Clear = async () => {
    const params = {
      time: "clear",
    };
    await Axios.put(`${process.env.REACT_APP_API_URL}/time`, params);
    await setStartDate(null);

  };

  useEffect(() => {
    getDate();
  }, []);

  return (
    <>
      <div className="text-center mt-5">
        <DatePicker
          selected={startDate}
          timeFormat="HH:mm"
          onChange={(date) => { Update(date) }}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={30}
          timeCaption="Time"
          dateFormat="HH:mm"
          isClearable
          inline
        >
        </DatePicker>
        <div className="mb-2 cursor-pointer" onClick={Clear}><span className="bg-body p-2 rounded-lg text-white leading-8">ดูข้อมูลคำสั่งซื้อทั้งวัน</span></div>
      </div>
    </>
  );
};

export default Timepick;
