import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Axios from "axios";
import moment from 'moment';
import { SpinnerCircularFixed } from "spinners-react";

const Datepick = () => {
  const [startDate, setStartDate] = useState();
  const [loading, setLoading] = useState(false);

  const getDate = async () => {
    setLoading(true)
    let data = await Axios.get(`${process.env.REACT_APP_API_URL}/date`);
    var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
    if (data.data[0].time) {
      var time = data.data[0].time + ":00";
    } else {
      time = "00:00:00";
    }
    var date = await new Date(data.data[0].date.replace(pattern, '$3-$2-$1') + 'T' + time);
    console.log(date);
    await setStartDate(date);
    await setLoading(false);
  }
  const Update = (date) => {
    setStartDate(date);
    const params = {
      date: moment(date).format('DD/MM/YYYY'),
    };
    Axios.put(`${process.env.REACT_APP_API_URL}/date`, params).then(
      () => {
        // window.location.reload();
      }
    );

  };

  useEffect(() => {
    getDate();
  }, []);

  return (
    <>
    {loading ? (
        <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden backdrop-blur-sm bg-white/30 flex flex-col items-center justify-center">
          <SpinnerCircularFixed size="100" color="#38bdf8"/>
        </div>
      ) : (
        ""
      )}
      <div className="text-center mt-5">
        <DatePicker
          dateFormat={"dd/MM/yyyy"}
          selected={startDate}
          timeFormat="HH:mm"
          onChange={(date) => { Update(date) }}
          className="p-2 w-[167px] rounded-full"
          inline
        />
      </div>
    </>
  );
};

export default Datepick;
